(function() {'use strict';var e,goog=goog||{},h=this;function l(a){return"string"==typeof a}function m(a,b){a=a.split(".");b=b||h;for(var c=0;c<a.length;c++)if(b=b[a[c]],null==b)return null;return b}function aa(){}
function ba(a){var b=typeof a;if("object"==b)if(a){if(a instanceof Array)return"array";if(a instanceof Object)return b;var c=Object.prototype.toString.call(a);if("[object Window]"==c)return"object";if("[object Array]"==c||"number"==typeof a.length&&"undefined"!=typeof a.splice&&"undefined"!=typeof a.propertyIsEnumerable&&!a.propertyIsEnumerable("splice"))return"array";if("[object Function]"==c||"undefined"!=typeof a.call&&"undefined"!=typeof a.propertyIsEnumerable&&!a.propertyIsEnumerable("call"))return"function"}else return"null";
else if("function"==b&&"undefined"==typeof a.call)return"object";return b}function n(a){return"array"==ba(a)}function ca(a){var b=ba(a);return"array"==b||"object"==b&&"number"==typeof a.length}function q(a){return"function"==ba(a)}function r(a){var b=typeof a;return"object"==b&&null!=a||"function"==b}var t="closure_uid_"+(1E9*Math.random()>>>0),da=0;function ea(a,b,c){return a.call.apply(a.bind,arguments)}
function fa(a,b,c){if(!a)throw Error();if(2<arguments.length){var d=Array.prototype.slice.call(arguments,2);return function(){var c=Array.prototype.slice.call(arguments);Array.prototype.unshift.apply(c,d);return a.apply(b,c)}}return function(){return a.apply(b,arguments)}}function u(a,b,c){u=Function.prototype.bind&&-1!=Function.prototype.bind.toString().indexOf("native code")?ea:fa;return u.apply(null,arguments)}
function v(a,b){var c=Array.prototype.slice.call(arguments,1);return function(){var b=c.slice();b.push.apply(b,arguments);return a.apply(this,b)}}var w=Date.now||function(){return+new Date};function x(a,b){function c(){}c.prototype=b.prototype;a.L=b.prototype;a.prototype=new c;a.prototype.constructor=a;a.sh=function(a,c,g){for(var d=Array(arguments.length-2),f=2;f<arguments.length;f++)d[f-2]=arguments[f];return b.prototype[c].apply(a,d)}};function y(a){if(Error.captureStackTrace)Error.captureStackTrace(this,y);else{var b=Error().stack;b&&(this.stack=b)}a&&(this.message=String(a))}x(y,Error);y.prototype.name="CustomError";function ha(a,b){a=a.split("%s");for(var c="",d=a.length-1,f=0;f<d;f++)c+=a[f]+(f<b.length?b[f]:"%s");y.call(this,c+a[d])}x(ha,y);ha.prototype.name="AssertionError";function ia(a,b){throw new ha("Failure"+(a?": "+a:""),Array.prototype.slice.call(arguments,1));};function z(){0!=ja&&(ka[this[t]||(this[t]=++da)]=this);this.Ka=this.Ka;this.Qa=this.Qa}var ja=0,ka={};z.prototype.Ka=!1;z.prototype.cb=function(){if(!this.Ka&&(this.Ka=!0,this.F(),0!=ja)){var a=this[t]||(this[t]=++da);if(0!=ja&&this.Qa&&0<this.Qa.length)throw Error(this+" did not empty its onDisposeCallbacks queue. This probably means it overrode dispose() or disposeInternal() without calling the superclass' method.");delete ka[a]}};z.prototype.F=function(){if(this.Qa)for(;this.Qa.length;)this.Qa.shift()()};var la=Array.prototype.indexOf?function(a,b){return Array.prototype.indexOf.call(a,b,void 0)}:function(a,b){if(l(a))return l(b)&&1==b.length?a.indexOf(b,0):-1;for(var c=0;c<a.length;c++)if(c in a&&a[c]===b)return c;return-1},ma=Array.prototype.lastIndexOf?function(a,b){return Array.prototype.lastIndexOf.call(a,b,a.length-1)}:function(a,b){var c=a.length-1;0>c&&(c=Math.max(0,a.length+c));if(l(a))return l(b)&&1==b.length?a.lastIndexOf(b,c):-1;for(;0<=c;c--)if(c in a&&a[c]===b)return c;return-1},na=
Array.prototype.forEach?function(a,b,c){Array.prototype.forEach.call(a,b,c)}:function(a,b,c){for(var d=a.length,f=l(a)?a.split(""):a,g=0;g<d;g++)g in f&&b.call(c,f[g],g,a)},oa=Array.prototype.some?function(a,b){return Array.prototype.some.call(a,b,void 0)}:function(a,b){for(var c=a.length,d=l(a)?a.split(""):a,f=0;f<c;f++)if(f in d&&b.call(void 0,d[f],f,a))return!0;return!1};
function pa(a){a:{var b=qa;for(var c=a.length,d=l(a)?a.split(""):a,f=0;f<c;f++)if(f in d&&b.call(void 0,d[f],f,a)){b=f;break a}b=-1}return 0>b?null:l(a)?a.charAt(b):a[b]}function ra(a){if(!n(a))for(var b=a.length-1;0<=b;b--)delete a[b];a.length=0}function sa(a,b){b=la(a,b);var c;(c=0<=b)&&Array.prototype.splice.call(a,b,1);return c}function ta(a){return Array.prototype.concat.apply([],arguments)}function ua(a){var b=a.length;if(0<b){for(var c=Array(b),d=0;d<b;d++)c[d]=a[d];return c}return[]};function va(a){return/^[\s\xa0]*$/.test(a)}var wa=String.prototype.trim?function(a){return a.trim()}:function(a){return/^[\s\xa0]*([\s\S]*?)[\s\xa0]*$/.exec(a)[1]};function xa(a,b){return a<b?-1:a>b?1:0};var A;a:{var ya=h.navigator;if(ya){var za=ya.userAgent;if(za){A=za;break a}}A=""}function B(a){return-1!=A.indexOf(a)};function Aa(a,b,c){for(var d in a)b.call(c,a[d],d,a)}function Ba(a){var b=[],c=0,d;for(d in a)b[c++]=a[d];return b}function Ca(a){var b=[],c=0,d;for(d in a)b[c++]=d;return b}function Da(a){var b={},c;for(c in a)b[c]=a[c];return b}var Ea="constructor hasOwnProperty isPrototypeOf propertyIsEnumerable toLocaleString toString valueOf".split(" ");
function Fa(a,b){for(var c,d,f=1;f<arguments.length;f++){d=arguments[f];for(c in d)a[c]=d[c];for(var g=0;g<Ea.length;g++)c=Ea[g],Object.prototype.hasOwnProperty.call(d,c)&&(a[c]=d[c])}};function Ga(a){Ga[" "](a);return a}Ga[" "]=aa;function Ha(a,b){var c=Ia;return Object.prototype.hasOwnProperty.call(c,a)?c[a]:c[a]=b(a)};var Ja=B("Opera"),C=B("Trident")||B("MSIE"),Ka=B("Edge"),La=Ka||C,Ma=B("Gecko")&&!(-1!=A.toLowerCase().indexOf("webkit")&&!B("Edge"))&&!(B("Trident")||B("MSIE"))&&!B("Edge"),Na=-1!=A.toLowerCase().indexOf("webkit")&&!B("Edge");function Oa(){var a=h.document;return a?a.documentMode:void 0}var Pa;
a:{var Qa="",Ra=function(){var a=A;if(Ma)return/rv:([^\);]+)(\)|;)/.exec(a);if(Ka)return/Edge\/([\d\.]+)/.exec(a);if(C)return/\b(?:MSIE|rv)[: ]([^\);]+)(\)|;)/.exec(a);if(Na)return/WebKit\/(\S+)/.exec(a);if(Ja)return/(?:Version)[ \/]?(\S+)/.exec(a)}();Ra&&(Qa=Ra?Ra[1]:"");if(C){var Sa=Oa();if(null!=Sa&&Sa>parseFloat(Qa)){Pa=String(Sa);break a}}Pa=Qa}var Ia={};
function Ta(a){return Ha(a,function(){for(var b=0,c=wa(String(Pa)).split("."),d=wa(String(a)).split("."),f=Math.max(c.length,d.length),g=0;0==b&&g<f;g++){var k=c[g]||"",p=d[g]||"";do{k=/(\d*)(\D*)(.*)/.exec(k)||["","","",""];p=/(\d*)(\D*)(.*)/.exec(p)||["","","",""];if(0==k[0].length&&0==p[0].length)break;b=xa(0==k[1].length?0:parseInt(k[1],10),0==p[1].length?0:parseInt(p[1],10))||xa(0==k[2].length,0==p[2].length)||xa(k[2],p[2]);k=k[3];p=p[3]}while(0==b)}return 0<=b})}var Ua;var Va=h.document;
Ua=Va&&C?Oa()||("CSS1Compat"==Va.compatMode?parseInt(Pa,10):5):void 0;var Wa=Object.freeze||function(a){return a};var Xa=!C||9<=Number(Ua),Ya=C&&!Ta("9"),Za=function(){if(!h.addEventListener||!Object.defineProperty)return!1;var a=!1,b=Object.defineProperty({},"passive",{get:function(){a=!0}});try{h.addEventListener("test",aa,b),h.removeEventListener("test",aa,b)}catch(c){}return a}();function D(a,b){this.type=a;this.currentTarget=this.target=b;this.defaultPrevented=this.Ea=!1;this.Ee=!0}D.prototype.stopPropagation=function(){this.Ea=!0};D.prototype.preventDefault=function(){this.defaultPrevented=!0;this.Ee=!1};function E(a,b){D.call(this,a?a.type:"");this.relatedTarget=this.currentTarget=this.target=null;this.button=this.screenY=this.screenX=this.clientY=this.clientX=this.offsetY=this.offsetX=0;this.key="";this.charCode=this.keyCode=0;this.metaKey=this.shiftKey=this.altKey=this.ctrlKey=!1;this.state=null;this.pointerId=0;this.pointerType="";this.gb=null;a&&this.Of(a,b)}x(E,D);var $a=Wa({2:"touch",3:"pen",4:"mouse"});
E.prototype.Of=function(a,b){var c=this.type=a.type,d=a.changedTouches?a.changedTouches[0]:null;this.target=a.target||a.srcElement;this.currentTarget=b;if(b=a.relatedTarget){if(Ma){a:{try{Ga(b.nodeName);var f=!0;break a}catch(g){}f=!1}f||(b=null)}}else"mouseover"==c?b=a.fromElement:"mouseout"==c&&(b=a.toElement);this.relatedTarget=b;null===d?(this.offsetX=Na||void 0!==a.offsetX?a.offsetX:a.layerX,this.offsetY=Na||void 0!==a.offsetY?a.offsetY:a.layerY,this.clientX=void 0!==a.clientX?a.clientX:a.pageX,
this.clientY=void 0!==a.clientY?a.clientY:a.pageY,this.screenX=a.screenX||0,this.screenY=a.screenY||0):(this.clientX=void 0!==d.clientX?d.clientX:d.pageX,this.clientY=void 0!==d.clientY?d.clientY:d.pageY,this.screenX=d.screenX||0,this.screenY=d.screenY||0);this.button=a.button;this.keyCode=a.keyCode||0;this.key=a.key||"";this.charCode=a.charCode||("keypress"==c?a.keyCode:0);this.ctrlKey=a.ctrlKey;this.altKey=a.altKey;this.shiftKey=a.shiftKey;this.metaKey=a.metaKey;this.pointerId=a.pointerId||0;this.pointerType=
l(a.pointerType)?a.pointerType:$a[a.pointerType]||"";this.state=a.state;this.gb=a;a.defaultPrevented&&this.preventDefault()};E.prototype.stopPropagation=function(){E.L.stopPropagation.call(this);this.gb.stopPropagation?this.gb.stopPropagation():this.gb.cancelBubble=!0};E.prototype.preventDefault=function(){E.L.preventDefault.call(this);var a=this.gb;if(a.preventDefault)a.preventDefault();else if(a.returnValue=!1,Ya)try{if(a.ctrlKey||112<=a.keyCode&&123>=a.keyCode)a.keyCode=-1}catch(b){}};var ab="closure_listenable_"+(1E6*Math.random()|0);function F(a){return!(!a||!a[ab])}var bb=0;function cb(a,b,c,d,f){this.listener=a;this.proxy=null;this.src=b;this.type=c;this.capture=!!d;this.Pb=f;this.key=++bb;this.Sa=this.Fb=!1}cb.prototype.Wb=function(){this.Sa=!0;this.Pb=this.src=this.proxy=this.listener=null};function db(a){this.src=a;this.J={};this.yb=0}e=db.prototype;e.add=function(a,b,c,d,f){var g=a.toString();a=this.J[g];a||(a=this.J[g]=[],this.yb++);var k=eb(a,b,d,f);-1<k?(b=a[k],c||(b.Fb=!1)):(b=new cb(b,this.src,g,!!d,f),b.Fb=c,a.push(b));return b};e.remove=function(a,b,c,d){a=a.toString();if(!(a in this.J))return!1;var f=this.J[a];b=eb(f,b,c,d);return-1<b?(f[b].Wb(),Array.prototype.splice.call(f,b,1),0==f.length&&(delete this.J[a],this.yb--),!0):!1};
e.Be=function(a){var b=a.type;b in this.J&&sa(this.J[b],a)&&(a.Wb(),0==this.J[b].length&&(delete this.J[b],this.yb--))};e.qb=function(a){a=a&&a.toString();var b=0,c;for(c in this.J)if(!a||c==a){for(var d=this.J[c],f=0;f<d.length;f++)++b,d[f].Wb();delete this.J[c];this.yb--}};e.kb=function(a,b,c,d){a=this.J[a.toString()];var f=-1;a&&(f=eb(a,b,c,d));return-1<f?a[f]:null};function eb(a,b,c,d){for(var f=0;f<a.length;++f){var g=a[f];if(!g.Sa&&g.listener==b&&g.capture==!!c&&g.Pb==d)return f}return-1};var fb="closure_lm_"+(1E6*Math.random()|0),gb={},hb=0;function ib(a,b,c,d,f){if(d&&d.once)return jb(a,b,c,d,f);if(n(b)){for(var g=0;g<b.length;g++)ib(a,b[g],c,d,f);return null}c=kb(c);return F(a)?a.ob(b,c,r(d)?!!d.capture:!!d,f):lb(a,b,c,!1,d,f)}
function lb(a,b,c,d,f,g){if(!b)throw Error("Invalid event type");var k=r(f)?!!f.capture:!!f,p=G(a);p||(a[fb]=p=new db(a));c=p.add(b,c,d,k,g);if(c.proxy)return c;d=mb();c.proxy=d;d.src=a;d.listener=c;if(a.addEventListener)Za||(f=k),void 0===f&&(f=!1),a.addEventListener(b.toString(),d,f);else if(a.attachEvent)a.attachEvent(nb(b.toString()),d);else if(a.addListener&&a.removeListener)a.addListener(d);else throw Error("addEventListener and attachEvent are unavailable.");hb++;return c}
function mb(){var a=ob,b=Xa?function(c){return a.call(b.src,b.listener,c)}:function(c){c=a.call(b.src,b.listener,c);if(!c)return c};return b}function jb(a,b,c,d,f){if(n(b)){for(var g=0;g<b.length;g++)jb(a,b[g],c,d,f);return null}c=kb(c);return F(a)?a.Qc(b,c,r(d)?!!d.capture:!!d,f):lb(a,b,c,!0,d,f)}function pb(a,b,c,d,f){if(n(b))for(var g=0;g<b.length;g++)pb(a,b[g],c,d,f);else d=r(d)?!!d.capture:!!d,c=kb(c),F(a)?a.hd(b,c,d,f):a&&(a=G(a))&&(b=a.kb(b,c,d,f))&&qb(b)}
function qb(a){if("number"!=typeof a&&a&&!a.Sa){var b=a.src;if(F(b))b.Pe(a);else{var c=a.type,d=a.proxy;b.removeEventListener?b.removeEventListener(c,d,a.capture):b.detachEvent?b.detachEvent(nb(c),d):b.addListener&&b.removeListener&&b.removeListener(d);hb--;(c=G(b))?(c.Be(a),0==c.yb&&(c.src=null,b[fb]=null)):a.Wb()}}}function nb(a){return a in gb?gb[a]:gb[a]="on"+a}
function rb(a,b,c,d){var f=!0;if(a=G(a))if(b=a.J[b.toString()])for(b=b.concat(),a=0;a<b.length;a++){var g=b[a];g&&g.capture==c&&!g.Sa&&(g=sb(g,d),f=f&&!1!==g)}return f}function sb(a,b){var c=a.listener,d=a.Pb||a.src;a.Fb&&qb(a);return c.call(d,b)}
function ob(a,b){if(a.Sa)return!0;if(!Xa){var c=b||m("window.event");b=new E(c,this);var d=!0;if(!(0>c.keyCode||void 0!=c.returnValue)){a:{var f=!1;if(0==c.keyCode)try{c.keyCode=-1;break a}catch(k){f=!0}if(f||void 0==c.returnValue)c.returnValue=!0}c=[];for(f=b.currentTarget;f;f=f.parentNode)c.push(f);a=a.type;for(f=c.length-1;!b.Ea&&0<=f;f--){b.currentTarget=c[f];var g=rb(c[f],a,!0,b);d=d&&g}for(f=0;!b.Ea&&f<c.length;f++)b.currentTarget=c[f],g=rb(c[f],a,!1,b),d=d&&g}return d}return sb(a,new E(b,this))}
function G(a){a=a[fb];return a instanceof db?a:null}var tb="__closure_events_fn_"+(1E9*Math.random()>>>0);function kb(a){if(q(a))return a;a[tb]||(a[tb]=function(b){return a.handleEvent(b)});return a[tb]};function H(){z.call(this);this.ka=new db(this);this.Te=this;this.Wc=null}x(H,z);H.prototype[ab]=!0;e=H.prototype;e.addEventListener=function(a,b,c,d){ib(this,a,b,c,d)};e.removeEventListener=function(a,b,c,d){pb(this,a,b,c,d)};
e.dispatchEvent=function(a){var b,c=this.Wc;if(c)for(b=[];c;c=c.Wc)b.push(c);c=this.Te;var d=a.type||a;if(l(a))a=new D(a,c);else if(a instanceof D)a.target=a.target||c;else{var f=a;a=new D(d,c);Fa(a,f)}f=!0;if(b)for(var g=b.length-1;!a.Ea&&0<=g;g--){var k=a.currentTarget=b[g];f=k.Mb(d,!0,a)&&f}a.Ea||(k=a.currentTarget=c,f=k.Mb(d,!0,a)&&f,a.Ea||(f=k.Mb(d,!1,a)&&f));if(b)for(g=0;!a.Ea&&g<b.length;g++)k=a.currentTarget=b[g],f=k.Mb(d,!1,a)&&f;return f};
e.F=function(){H.L.F.call(this);this.tg();this.Wc=null};e.ob=function(a,b,c,d){return this.ka.add(String(a),b,!1,c,d)};e.Qc=function(a,b,c,d){return this.ka.add(String(a),b,!0,c,d)};e.hd=function(a,b,c,d){this.ka.remove(String(a),b,c,d)};e.Pe=function(a){this.ka.Be(a)};e.tg=function(){this.ka&&this.ka.qb(void 0)};
e.Mb=function(a,b,c){a=this.ka.J[String(a)];if(!a)return!0;a=a.concat();for(var d=!0,f=0;f<a.length;++f){var g=a[f];if(g&&!g.Sa&&g.capture==b){var k=g.listener,p=g.Pb||g.src;g.Fb&&this.Pe(g);d=!1!==k.call(p,c)&&d}}return d&&0!=c.Ee};e.kb=function(a,b,c,d){return this.ka.kb(String(a),b,c,d)};function ub(a){return/^\s*$/.test(a)?!1:/^[\],:{}\s\u2028\u2029]*$/.test(a.replace(/\\["\\\/bfnrtu]/g,"@").replace(/(?:"[^"\\\n\r\u2028\u2029\x00-\x08\x0a-\x1f]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)[\s\u2028\u2029]*(?=:|,|]|}|$)/g,"]").replace(/(?:^|:|,)(?:[\s\u2028\u2029]*\[)+/g,""))}function vb(a){a=String(a);if(ub(a))try{return eval("("+a+")")}catch(b){}throw Error("Invalid JSON string: "+a);}function I(){this.Ta=void 0}I.prototype.ec=function(a){var b=[];this.bd(a,b);return b.join("")};
I.prototype.bd=function(a,b){if(null==a)b.push("null");else{if("object"==typeof a){if(n(a)){this.Dg(a,b);return}if(a instanceof String||a instanceof Number||a instanceof Boolean)a=a.valueOf();else{this.Fg(a,b);return}}switch(typeof a){case "string":this.Ge(a,b);break;case "number":this.Eg(a,b);break;case "boolean":b.push(String(a));break;case "function":b.push("null");break;default:throw Error("Unknown type: "+typeof a);}}};
var wb={'"':'\\"',"\\":"\\\\","/":"\\/","\b":"\\b","\f":"\\f","\n":"\\n","\r":"\\r","\t":"\\t","\x0B":"\\u000b"},xb=/\uffff/.test("\uffff")?/[\\"\x00-\x1f\x7f-\uffff]/g:/[\\"\x00-\x1f\x7f-\xff]/g;I.prototype.Ge=function(a,b){b.push('"',a.replace(xb,function(a){var b=wb[a];b||(b="\\u"+(a.charCodeAt(0)|65536).toString(16).substr(1),wb[a]=b);return b}),'"')};I.prototype.Eg=function(a,b){b.push(isFinite(a)&&!isNaN(a)?String(a):"null")};
I.prototype.Dg=function(a,b){var c=a.length;b.push("[");for(var d="",f=0;f<c;f++)b.push(d),d=a[f],this.bd(this.Ta?this.Ta.call(a,String(f),d):d,b),d=",";b.push("]")};I.prototype.Fg=function(a,b){b.push("{");var c="",d;for(d in a)if(Object.prototype.hasOwnProperty.call(a,d)){var f=a[d];"function"!=typeof f&&(b.push(c),this.Ge(d,b),b.push(":"),this.bd(this.Ta?this.Ta.call(a,d,f):f,b),c=",")}b.push("}")};function yb(a,b){this.Wf=100;this.jf=a;this.xg=b;this.$b=0;this.Qb=null}yb.prototype.get=function(){if(0<this.$b){this.$b--;var a=this.Qb;this.Qb=a.next;a.next=null}else a=this.jf();return a};yb.prototype.put=function(a){this.xg(a);this.$b<this.Wf&&(this.$b++,a.next=this.Qb,this.Qb=a)};function J(){this.nc=this.Wa=null}var Ab=new yb(function(){return new zb},function(a){a.reset()});J.prototype.add=function(a,b){var c=this.Ef();c.set(a,b);this.nc?this.nc.next=c:this.Wa=c;this.nc=c};J.prototype.remove=function(){var a=null;this.Wa&&(a=this.Wa,this.Wa=this.Wa.next,this.Wa||(this.nc=null),a.next=null);return a};J.prototype.zg=function(a){Ab.put(a)};J.prototype.Ef=function(){return Ab.get()};function zb(){this.next=this.scope=this.Ic=null}
zb.prototype.set=function(a,b){this.Ic=a;this.scope=b;this.next=null};zb.prototype.reset=function(){this.next=this.scope=this.Ic=null};function Bb(a){h.setTimeout(function(){throw a;},0)}var Cb;
function Db(){var a=h.MessageChannel;"undefined"===typeof a&&"undefined"!==typeof window&&window.postMessage&&window.addEventListener&&!B("Presto")&&(a=function(){var a=document.createElement("IFRAME");a.style.display="none";a.src="";document.documentElement.appendChild(a);var b=a.contentWindow;a=b.document;a.open();a.write("");a.close();var c="callImmediate"+Math.random(),d="file:"==b.location.protocol?"*":b.location.protocol+"//"+b.location.host;a=u(function(a){if(("*"==d||a.origin==d)&&a.data==
c)this.port1.onmessage()},this);b.addEventListener("message",a,!1);this.port1={};this.port2={postMessage:function(){b.postMessage(c,d)}}});if("undefined"!==typeof a&&!B("Trident")&&!B("MSIE")){var b=new a,c={},d=c;b.port1.onmessage=function(){if(void 0!==c.next){c=c.next;var a=c.ud;c.ud=null;a()}};return function(a){d.next={ud:a};d=d.next;b.port2.postMessage(0)}}return"undefined"!==typeof document&&"onreadystatechange"in document.createElement("SCRIPT")?function(a){var b=document.createElement("SCRIPT");
b.onreadystatechange=function(){b.onreadystatechange=null;b.parentNode.removeChild(b);b=null;a();a=null};document.documentElement.appendChild(b)}:function(a){h.setTimeout(a,0)}};var Eb;function Fb(){if(h.Promise&&h.Promise.resolve){var a=h.Promise.resolve(void 0);Eb=function(){a.then(Gb)}}else Eb=function(){var a=Gb;!q(h.setImmediate)||h.Window&&h.Window.prototype&&!B("Edge")&&h.Window.prototype.setImmediate==h.setImmediate?(Cb||(Cb=Db()),Cb(a)):h.setImmediate(a)}}var Hb=!1,Ib=new J;function Gb(){for(var a;a=Ib.remove();){try{a.Ic.call(a.scope)}catch(b){Bb(b)}Ib.zg(a)}Hb=!1};function Jb(a,b){H.call(this);this.Na=a||1;this.xb=b||h;this.qd=u(this.Xg,this);this.le=w()}x(Jb,H);e=Jb.prototype;e.enabled=!1;e.B=null;e.setInterval=function(a){this.Na=a;this.B&&this.enabled?(this.stop(),this.start()):this.B&&this.stop()};e.Xg=function(){if(this.enabled){var a=w()-this.le;0<a&&a<.8*this.Na?this.B=this.xb.setTimeout(this.qd,this.Na-a):(this.B&&(this.xb.clearTimeout(this.B),this.B=null),this.kf(),this.enabled&&(this.stop(),this.start()))}};e.kf=function(){this.dispatchEvent("tick")};
e.start=function(){this.enabled=!0;this.B||(this.B=this.xb.setTimeout(this.qd,this.Na),this.le=w())};e.stop=function(){this.enabled=!1;this.B&&(this.xb.clearTimeout(this.B),this.B=null)};e.F=function(){Jb.L.F.call(this);this.stop();delete this.xb};function Kb(a,b,c){if(q(a))c&&(a=u(a,c));else if(a&&"function"==typeof a.handleEvent)a=u(a.handleEvent,a);else throw Error("Invalid listener argument");return 2147483647<Number(b)?-1:h.setTimeout(a,b||0)};function Lb(a,b,c){z.call(this);this.Yf=null!=c?u(a,c):a;this.Na=b;this.af=u(this.jg,this);this.sc=[]}x(Lb,z);e=Lb.prototype;e.Ua=!1;e.pb=0;e.B=null;e.rf=function(a){this.sc=arguments;this.B||this.pb?this.Ua=!0:this.Ec()};e.stop=function(){this.B&&(h.clearTimeout(this.B),this.B=null,this.Ua=!1,this.sc=[])};e.pause=function(){this.pb++};e.resume=function(){this.pb--;this.pb||!this.Ua||this.B||(this.Ua=!1,this.Ec())};e.F=function(){Lb.L.F.call(this);this.stop()};
e.jg=function(){this.B=null;this.Ua&&!this.pb&&(this.Ua=!1,this.Ec())};e.Ec=function(){this.B=Kb(this.af,this.Na);this.Yf.apply(null,this.sc)};function Mb(a){z.call(this);this.i=a;this.o={}}x(Mb,z);var Nb=[];e=Mb.prototype;e.ob=function(a,b,c,d){return this.Xf(a,b,c,d)};e.Xf=function(a,b,c,d){n(b)||(b&&(Nb[0]=b.toString()),b=Nb);for(var f=0;f<b.length;f++){var g=ib(a,b[f],c||this.handleEvent,d||!1,this.i||this);if(!g)break;this.o[g.key]=g}return this};e.Qc=function(a,b,c,d){return this.me(a,b,c,d)};
e.me=function(a,b,c,d,f){if(n(b))for(var g=0;g<b.length;g++)this.me(a,b[g],c,d,f);else{a=jb(a,b,c||this.handleEvent,d,f||this.i||this);if(!a)return this;this.o[a.key]=a}return this};e.hd=function(a,b,c,d,f){if(n(b))for(var g=0;g<b.length;g++)this.hd(a,b[g],c,d,f);else c=c||this.handleEvent,d=r(d)?!!d.capture:!!d,f=f||this.i||this,c=kb(c),d=!!d,b=F(a)?a.kb(b,c,d,f):a?(a=G(a))?a.kb(b,c,d,f):null:null,b&&(qb(b),delete this.o[b.key])};
e.qb=function(){Aa(this.o,function(a,b){this.o.hasOwnProperty(b)&&qb(a)},this);this.o={}};e.F=function(){Mb.L.F.call(this);this.qb()};e.handleEvent=function(){throw Error("EventHandler.handleEvent not implemented");};function K(a,b,c){this.reset(a,b,c,void 0,void 0)}K.prototype.Pd=null;var Ob=0;K.prototype.reset=function(a,b,c,d,f){"number"==typeof f||Ob++;d||w();this.nb=a;delete this.Pd};K.prototype.Hg=function(a){this.Pd=a};K.prototype.Ke=function(a){this.nb=a};function Pb(a){this.se=a;this.be=this.wc=this.nb=this.ac=null}function L(a,b){this.name=a;this.value=b}L.prototype.toString=function(){return this.name};var Qb=new L("SEVERE",1E3),Rb=new L("WARNING",900),Sb=new L("INFO",800),Tb=new L("CONFIG",700),Ub=new L("FINE",500);e=Pb.prototype;e.getName=function(){return this.se};e.getParent=function(){return this.ac};e.tf=function(){this.wc||(this.wc={});return this.wc};e.Ke=function(a){this.nb=a};
e.Td=function(){if(this.nb)return this.nb;if(this.ac)return this.ac.Td();ia("Root logger has no level set.");return null};e.Tf=function(a){return a.value>=this.Td().value};e.log=function(a,b,c){this.Tf(a)&&(q(b)&&(b=b()),this.lf(this.yf(a,b,c)))};e.yf=function(a,b,c){a=new K(a,String(b),this.se);c&&a.Hg(c);return a};e.ca=function(a,b){this.log(Qb,a,b)};e.T=function(a,b){this.log(Rb,a,b)};e.info=function(a,b){this.log(Sb,a,b)};e.qf=function(a){this.log(Ub,a,void 0)};
e.lf=function(a){for(var b=this;b;)b.$e(a),b=b.getParent()};e.$e=function(a){if(this.be)for(var b=0,c;c=this.be[b];b++)c(a)};e.Lg=function(a){this.ac=a};e.Ue=function(a,b){this.tf()[a]=b};var Wb={},Xb=null;function Yb(a){Xb||(Xb=new Pb(""),Wb[""]=Xb,Xb.Ke(Tb));var b;if(!(b=Wb[a])){b=new Pb(a);var c=a.lastIndexOf("."),d=a.substr(c+1);c=Yb(a.substr(0,c));c.Ue(d,b);b.Lg(c);Wb[a]=b}return b};function Zb(a,b){a&&a.info(b,void 0)}function M(a,b){a&&a.qf(b)};function $b(){this.s=Yb("goog.labs.net.webChannel.WebChannelDebug");this.Yc=!0}e=$b.prototype;e.Ld=function(){this.Yc=!1};e.Zg=function(a,b,c,d,f){var g=this;this.info(function(){return"XMLHTTP REQ ("+c+") [attempt "+d+"]: "+a+"\n"+b+"\n"+g.ag(f)})};e.$g=function(a,b,c,d,f,g){this.info(function(){return"XMLHTTP RESP ("+c+") [ attempt "+d+"]: "+a+"\n"+b+"\n"+f+" "+g})};e.Xa=function(a,b,c){var d=this;this.info(function(){return"XMLHTTP TEXT ("+a+"): "+d.rg(b)+(c?" "+c:"")})};
e.Yg=function(a){this.info(function(){return"TIMEOUT: "+a})};e.debug=function(a){M(this.s,a)};e.eb=function(a,b){var c=this.s;c&&c.ca(b||"Exception",a)};e.info=function(a){Zb(this.s,a)};e.T=function(a){var b=this.s;b&&b.T(a,void 0)};e.ca=function(a){var b=this.s;b&&b.ca(a,void 0)};
e.rg=function(a){if(!this.Yc)return a;if(!a)return null;try{var b=JSON.parse(a);if(b)for(var c=0;c<b.length;c++)n(b[c])&&this.$f(b[c]);return(new I).ec(b)}catch(d){return this.debug("Exception parsing expected JS array - probably was not JS"),a}};e.$f=function(a){if(!(2>a.length||(a=a[1],!n(a)||1>a.length))){var b=a[0];if("noop"!=b&&"stop"!=b&&"close"!=b)for(b=1;b<a.length;b++)a[b]=""}};
e.ag=function(a){if(!this.Yc)return a;if(!a)return null;var b="";a=a.split("&");for(var c=0;c<a.length;c++){var d=a[c].split("=");if(1<d.length){var f=d[0];d=d[1];var g=f.split("_");b=2<=g.length&&"type"==g[1]?b+(f+"="+d+"&"):b+(f+"=redacted&")}}return b};var N=new H;function ac(a){D.call(this,"serverreachability",a)}x(ac,D);function O(a){N.dispatchEvent(new ac(N,a))}function bc(a,b){D.call(this,"statevent",a);this.stat=b}x(bc,D);function P(a){N.dispatchEvent(new bc(N,a))}function cc(a,b,c){D.call(this,"timingevent",a);this.size=b;this.rtt=c}x(cc,D);function dc(a,b,c){N.dispatchEvent(new cc(N,a,b,c))}function Q(a,b){if(!q(a))throw Error("Fn must not be null and must be a function");return h.setTimeout(function(){a()},b)};var ec={NO_ERROR:0,ah:1,ih:2,hh:3,eh:4,gh:5,jh:6,Re:7,TIMEOUT:8,mh:9};var fc={dh:"complete",qh:"success",Se:"error",Re:"abort",oh:"ready",ph:"readystatechange",TIMEOUT:"timeout",kh:"incrementaldata",nh:"progress",fh:"downloadprogress",rh:"uploadprogress"};function gc(){}gc.prototype.sd=null;gc.prototype.Yd=function(){return this.sd||(this.sd=this.Qf())};function hc(){}var ic={OPEN:"a",bh:"b",Se:"c",lh:"d"};function jc(){D.call(this,"d")}x(jc,D);function kc(){D.call(this,"c")}x(kc,D);var lc;function mc(){}x(mc,gc);mc.prototype.Gd=function(){var a=this.Zd();return a?new ActiveXObject(a):new XMLHttpRequest};mc.prototype.Qf=function(){var a={};this.Zd()&&(a[0]=!0,a[1]=!0);return a};
mc.prototype.Zd=function(){if(!this.ee&&"undefined"==typeof XMLHttpRequest&&"undefined"!=typeof ActiveXObject){for(var a=["MSXML2.XMLHTTP.6.0","MSXML2.XMLHTTP.3.0","MSXML2.XMLHTTP","Microsoft.XMLHTTP"],b=0;b<a.length;b++){var c=a[b];try{return new ActiveXObject(c),this.ee=c}catch(d){}}throw Error("Could not create ActiveXObject. ActiveX might be disabled, or MSXML might not be installed");}return this.ee};lc=new mc;function R(a,b,c,d,f){this.b=a;this.a=b;this.ra=c;this.R=d;this.Zc=f||1;this.Hc=new Mb(this);this.Va=nc;a=La?125:void 0;this.Xc=new Jb(a);this.A=null;this.S=!1;this.Da=this.pa=this.ua=this.kc=this.rb=this.ld=this.Ga=null;this.ba=[];this.h=null;this.Cb=0;this.I=this.Fa=null;this.w=-1;this.$a=!1;this.Ra=0;this.bc=null;this.mb=this.Hd=this.Ac=!1}var nc=45E3;
function oc(a,b){switch(a){case 0:return"Non-200 return code ("+b+")";case 1:return"XMLHTTP failure (no data)";case 2:return"HttpConnection timeout";default:return"Unknown error"}}var pc={},qc={};e=R.prototype;e.ga=function(a){this.A=a};e.setTimeout=function(a){this.Va=a};e.Le=function(a){this.Ra=a};e.Mg=function(a){this.ba=a};e.la=function(){return this.ba};e.nd=function(a,b){this.kc=1;this.ua=a.clone().Vb();this.Da=b;this.Ac=!0;this.Fe(null)};
e.md=function(a,b,c){this.kc=1;this.ua=a.clone().Vb();this.Da=null;this.Ac=b;this.Fe(c)};
e.Fe=function(a){this.rb=w();this.fb();this.pa=this.ua.clone();this.pa.fc("t",this.Zc);this.Cb=0;this.h=this.b.Kb(this.b.hc()?a:null);0<this.Ra&&(this.bc=new Lb(u(this.Qe,this,this.h),this.Ra));this.Hc.ob(this.h,"readystatechange",this.qg);a=this.A?Da(this.A):{};this.Da?(this.Fa||(this.Fa="POST"),a["Content-Type"]="application/x-www-form-urlencoded",this.h.send(this.pa,this.Fa,this.Da,a)):(this.Fa="GET",this.h.send(this.pa,this.Fa,null,a));O(1);this.a.Zg(this.Fa,this.pa,this.R,this.Zc,this.Da)};
e.qg=function(a){a=a.target;var b=this.bc;b&&3==a.ma()?(this.a.debug("Throttling readystatechange."),b.rf()):this.Qe(a)};e.Qe=function(a){try{a==this.h?this.lg():this.a.T("Called back with an unexpected xmlhttp")}catch(c){if(this.a.debug("Failed call to OnXmlHttpReadyStateChanged_"),this.h&&this.h.ya()){var b=this;this.a.eb(c,function(){return"ResponseText: "+b.h.ya()})}else this.a.eb(c,"No response text")}finally{}};
e.lg=function(){var a=this.h.ma(),b=this.h.Xd(),c=this.h.za();if(!(3>a||3==a&&!La&&!this.h.ya())){this.$a||4!=a||7==b||(8==b||0>=c?O(3):O(2));this.Gb();var d=this.h.za();this.w=d;b=this.h.ya();if(!b){var f=this;this.a.debug(function(){return"No response text for uri "+f.pa+" status "+d})}this.S=200==d;this.a.$g(this.Fa,this.pa,this.R,this.Zc,a,d);if(this.S){if(this.Og())if(c=this.wf())this.a.Xa(this.R,c,"Initial handshake response via X-HTTP-Initial-Response"),this.mb=!0,this.$c(c);else{this.S=!1;
this.I=3;P(12);this.a.T("XMLHTTP Missing X_HTTP_INITIAL_RESPONSE ("+this.R+")");this.Ia();this.Lb();return}this.Ac?(this.Id(a,b),La&&this.S&&3==a&&this.Tg()):(this.a.Xa(this.R,b,null),this.$c(b));4==a&&this.Ia();this.S&&!this.$a&&(4==a?this.b.Vc(this):(this.S=!1,this.fb()))}else 400==d&&0<b.indexOf("Unknown SID")?(this.I=3,P(12),this.a.T("XMLHTTP Unknown SID ("+this.R+")")):(this.I=0,P(13),this.a.T("XMLHTTP Bad status "+d+" ("+this.R+")")),this.Ia(),this.Lb()}};e.Og=function(){return this.Hd&&!this.mb};
e.wf=function(){if(this.h){var a=this.h.lb("X-HTTP-Initial-Response");if(a&&!va(a))return a}return null};e.Gg=function(){this.Hd=!0};
e.Id=function(a,b){for(var c=!0;!this.$a&&this.Cb<b.length;){var d=this.zf(b);if(d==qc){4==a&&(this.I=4,P(14),c=!1);this.a.Xa(this.R,null,"[Incomplete Response]");break}else if(d==pc){this.I=4;P(15);this.a.Xa(this.R,b,"[Invalid Chunk]");c=!1;break}else this.a.Xa(this.R,d,null),this.$c(d)}4==a&&0==b.length&&(this.I=1,P(16),c=!1);this.S=this.S&&c;c||(this.a.Xa(this.R,b,"[Invalid Chunked Response]"),this.Ia(),this.Lb())};
e.og=function(){if(this.h){var a=this.h.ma(),b=this.h.ya();this.Cb<b.length&&(this.Gb(),this.Id(a,b),this.S&&4!=a&&this.fb())}};e.Tg=function(){this.Hc.ob(this.Xc,"tick",this.og);this.Xc.start()};e.zf=function(a){var b=this.Cb,c=a.indexOf("\n",b);if(-1==c)return qc;b=Number(a.substring(b,c));if(isNaN(b))return pc;c+=1;if(c+b>a.length)return qc;a=a.substr(c,b);this.Cb=c+b;return a};
e.Bg=function(a){this.kc=2;this.ua=a.clone().Vb();a=!1;h.navigator&&h.navigator.sendBeacon&&(a=h.navigator.sendBeacon(this.ua.toString(),""));!a&&h.Image&&((new Image).src=this.ua,a=!0);a||(this.h=this.b.Kb(null),this.h.send(this.ua));this.rb=w();this.fb()};e.cancel=function(){this.$a=!0;this.Ia()};e.wg=function(a){a&&this.setTimeout(a);this.Ga&&(this.Gb(),this.fb())};e.fb=function(){this.ld=w()+this.Va;this.Oe(this.Va)};
e.Oe=function(a){if(null!=this.Ga)throw Error("WatchDog timer not null");this.Ga=Q(u(this.kg,this),a)};e.Gb=function(){this.Ga&&(h.clearTimeout(this.Ga),this.Ga=null)};e.kg=function(){this.Ga=null;var a=w();0<=a-this.ld?this.Hf():(this.a.T("WatchDog timer called too early"),this.Oe(this.ld-a))};e.Hf=function(){this.S&&this.a.ca("Received watchdog timeout even though request loaded successfully");this.a.Yg(this.pa);2!=this.kc&&(O(3),P(17));this.Ia();this.I=2;this.Lb()};
e.Lb=function(){this.b.ge()||this.$a||this.b.Vc(this)};e.Ia=function(){this.Gb();var a=this.bc;a&&"function"==typeof a.cb&&a.cb();this.bc=null;this.Xc.stop();this.Hc.qb();this.h&&(a=this.h,this.h=null,a.abort(),a.cb())};e.Jc=function(){return this.I};e.$c=function(a){try{this.b.xe(this,a),O(4)}catch(b){this.a.eb(b,"Error in httprequest callback")}};function rc(a){if(a.H&&"function"==typeof a.H)return a.H();if(l(a))return a.split("");if(ca(a)){for(var b=[],c=a.length,d=0;d<c;d++)b.push(a[d]);return b}return Ba(a)}
function sc(a,b,c){if(a.forEach&&"function"==typeof a.forEach)a.forEach(b,c);else if(ca(a)||l(a))na(a,b,c);else{if(a.W&&"function"==typeof a.W)var d=a.W();else if(a.H&&"function"==typeof a.H)d=void 0;else if(ca(a)||l(a)){d=[];for(var f=a.length,g=0;g<f;g++)d.push(g)}else d=Ca(a);f=rc(a);g=f.length;for(var k=0;k<g;k++)b.call(c,f[k],d&&d[k],a)}};function S(a,b){this.D={};this.o=[];this.j=0;var c=arguments.length;if(1<c){if(c%2)throw Error("Uneven number of arguments");for(var d=0;d<c;d+=2)this.set(arguments[d],arguments[d+1])}else a&&this.addAll(a)}e=S.prototype;e.C=function(){return this.j};e.H=function(){this.yc();for(var a=[],b=0;b<this.o.length;b++)a.push(this.D[this.o[b]]);return a};e.W=function(){this.yc();return this.o.concat()};e.va=function(a){return T(this.D,a)};e.X=function(){return 0==this.j};
e.clear=function(){this.D={};this.j=this.o.length=0};e.remove=function(a){return T(this.D,a)?(delete this.D[a],this.j--,this.o.length>2*this.j&&this.yc(),!0):!1};e.yc=function(){if(this.j!=this.o.length){for(var a=0,b=0;a<this.o.length;){var c=this.o[a];T(this.D,c)&&(this.o[b++]=c);a++}this.o.length=b}if(this.j!=this.o.length){var d={};for(b=a=0;a<this.o.length;)c=this.o[a],T(d,c)||(this.o[b++]=c,d[c]=1),a++;this.o.length=b}};e.get=function(a,b){return T(this.D,a)?this.D[a]:b};
e.set=function(a,b){T(this.D,a)||(this.j++,this.o.push(a));this.D[a]=b};e.addAll=function(a){if(a instanceof S)for(var b=a.W(),c=0;c<b.length;c++)this.set(b[c],a.get(b[c]));else for(b in a)this.set(b,a[b])};e.forEach=function(a,b){for(var c=this.W(),d=0;d<c.length;d++){var f=c[d],g=this.get(f);a.call(b,g,f,this)}};e.clone=function(){return new S(this)};function T(a,b){return Object.prototype.hasOwnProperty.call(a,b)};var tc=/^(?:([^:/?#.]+):)?(?:\/\/(?:([^/?#]*)@)?([^/#?]*?)(?::([0-9]+))?(?=[/#?]|$))?([^?#]+)?(?:\?([^#]*))?(?:#([\s\S]*))?$/;function uc(a,b){if(a){a=a.split("&");for(var c=0;c<a.length;c++){var d=a[c].indexOf("="),f=null;if(0<=d){var g=a[c].substring(0,d);f=a[c].substring(d+1)}else g=a[c];b(g,f?decodeURIComponent(f.replace(/\+/g," ")):"")}}};function U(a,b){this.xa=this.Ab=this.qa="";this.Ca=null;this.jb=this.K="";this.O=this.Uf=!1;var c;a instanceof U?(this.O=void 0!==b?b:a.O,this.ub(a.qa),this.fd(a.Ab),this.sb(a.xa),this.tb(a.Ca),this.gc(a.K),this.ed(a.P.clone()),this.cd(a.jb)):a&&(c=String(a).match(tc))?(this.O=!!b,this.ub(c[1]||"",!0),this.fd(c[2]||"",!0),this.sb(c[3]||"",!0),this.tb(c[4]),this.gc(c[5]||"",!0),this.ed(c[6]||"",!0),this.cd(c[7]||"",!0)):(this.O=!!b,this.P=new vc(null,this.O))}e=U.prototype;
e.toString=function(){var a=[],b=this.qa;b&&a.push(wc(b,yc,!0),":");var c=this.xa;if(c||"file"==b)a.push("//"),(b=this.Ab)&&a.push(wc(b,yc,!0),"@"),a.push(encodeURIComponent(String(c)).replace(/%25([0-9a-fA-F]{2})/g,"%$1")),c=this.Ca,null!=c&&a.push(":",String(c));if(c=this.K)this.Kc()&&"/"!=c.charAt(0)&&a.push("/"),a.push(wc(c,"/"==c.charAt(0)?zc:Ac,!0));(c=this.Ud())&&a.push("?",c);(c=this.jb)&&a.push("#",wc(c,Bc));return a.join("")};
e.resolve=function(a){var b=this.clone(),c=a.Lf();c?b.ub(a.qa):c=a.Mf();c?b.fd(a.Ab):c=a.Kc();c?b.sb(a.xa):c=a.Jf();var d=a.K;if(c)b.tb(a.Ca);else if(c=a.de()){if("/"!=d.charAt(0))if(this.Kc()&&!this.de())d="/"+d;else{var f=b.K.lastIndexOf("/");-1!=f&&(d=b.K.substr(0,f+1)+d)}f=d;if(".."==f||"."==f)d="";else if(-1!=f.indexOf("./")||-1!=f.indexOf("/.")){d=0==f.lastIndexOf("/",0);f=f.split("/");for(var g=[],k=0;k<f.length;){var p=f[k++];"."==p?d&&k==f.length&&g.push(""):".."==p?((1<g.length||1==g.length&&
""!=g[0])&&g.pop(),d&&k==f.length&&g.push("")):(g.push(p),d=!0)}d=g.join("/")}else d=f}c?b.gc(d):c=a.Kf();c?b.ed(a.P.clone()):c=a.If();c&&b.cd(a.jb);return b};e.clone=function(){return new U(this)};e.ub=function(a,b){this.U();if(this.qa=b?Cc(a,!0):a)this.qa=this.qa.replace(/:$/,"")};e.Lf=function(){return!!this.qa};e.fd=function(a,b){this.U();this.Ab=b?Cc(a):a};e.Mf=function(){return!!this.Ab};e.sb=function(a,b){this.U();this.xa=b?Cc(a,!0):a};e.Kc=function(){return!!this.xa};
e.tb=function(a){this.U();if(a){a=Number(a);if(isNaN(a)||0>a)throw Error("Bad port number "+a);this.Ca=a}else this.Ca=null};e.Jf=function(){return null!=this.Ca};e.gc=function(a,b){this.U();this.K=b?Cc(a,!0):a};e.de=function(){return!!this.K};e.Kf=function(){return""!==this.P.toString()};e.ed=function(a,b){this.U();a instanceof vc?(this.P=a,this.P.dd(this.O)):(b||(a=wc(a,Dc)),this.P=new vc(a,this.O))};e.Ud=function(){return this.P.toString()};e.getQuery=function(){return this.Ud()};
e.l=function(a,b){this.U();this.P.set(a,b)};e.fc=function(a,b){this.U();n(b)||(b=[String(b)]);this.P.Me(a,b)};e.cd=function(a,b){this.U();this.jb=b?Cc(a):a};e.If=function(){return!!this.jb};e.Vb=function(){this.U();this.l("zx",Math.floor(2147483648*Math.random()).toString(36)+Math.abs(Math.floor(2147483648*Math.random())^w()).toString(36));return this};e.removeParameter=function(a){this.U();this.P.remove(a);return this};e.U=function(){if(this.Uf)throw Error("Tried to modify a read-only Uri");};
e.dd=function(a){this.O=a;this.P&&this.P.dd(a)};function Ec(a){return a instanceof U?a.clone():new U(a,void 0)}function Fc(a,b,c,d){var f=new U(null,void 0);a&&f.ub(a);b&&f.sb(b);c&&f.tb(c);d&&f.gc(d);return f}function Cc(a,b){return a?b?decodeURI(a.replace(/%25/g,"%2525")):decodeURIComponent(a):""}function wc(a,b,c){return l(a)?(a=encodeURI(a).replace(b,Gc),c&&(a=a.replace(/%25([0-9a-fA-F]{2})/g,"%$1")),a):null}
function Gc(a){a=a.charCodeAt(0);return"%"+(a>>4&15).toString(16)+(a&15).toString(16)}var yc=/[#\/\?@]/g,Ac=/[#\?:]/g,zc=/[#\?]/g,Dc=/[#\?@]/g,Bc=/#/g;function vc(a,b){this.j=this.m=null;this.ja=a||null;this.O=!!b}e=vc.prototype;e.$=function(){if(!this.m&&(this.m=new S,this.j=0,this.ja)){var a=this;uc(this.ja,function(b,c){a.add(decodeURIComponent(b.replace(/\+/g," ")),c)})}};e.C=function(){this.$();return this.j};
e.add=function(a,b){this.$();this.Oa();a=this.Ma(a);var c=this.m.get(a);c||this.m.set(a,c=[]);c.push(b);this.j+=1;return this};e.remove=function(a){this.$();a=this.Ma(a);return this.m.va(a)?(this.Oa(),this.j-=this.m.get(a).length,this.m.remove(a)):!1};e.clear=function(){this.Oa();this.m=null;this.j=0};e.X=function(){this.$();return 0==this.j};e.va=function(a){this.$();a=this.Ma(a);return this.m.va(a)};
e.forEach=function(a,b){this.$();this.m.forEach(function(c,d){na(c,function(c){a.call(b,c,d,this)},this)},this)};e.W=function(){this.$();for(var a=this.m.H(),b=this.m.W(),c=[],d=0;d<b.length;d++)for(var f=a[d],g=0;g<f.length;g++)c.push(b[d]);return c};e.H=function(a){this.$();var b=[];if(l(a))this.va(a)&&(b=ta(b,this.m.get(this.Ma(a))));else{a=this.m.H();for(var c=0;c<a.length;c++)b=ta(b,a[c])}return b};
e.set=function(a,b){this.$();this.Oa();a=this.Ma(a);this.va(a)&&(this.j-=this.m.get(a).length);this.m.set(a,[b]);this.j+=1;return this};e.get=function(a,b){if(!a)return b;a=this.H(a);return 0<a.length?String(a[0]):b};e.Me=function(a,b){this.remove(a);0<b.length&&(this.Oa(),this.m.set(this.Ma(a),ua(b)),this.j+=b.length)};
e.toString=function(){if(this.ja)return this.ja;if(!this.m)return"";for(var a=[],b=this.m.W(),c=0;c<b.length;c++){var d=b[c],f=encodeURIComponent(String(d));d=this.H(d);for(var g=0;g<d.length;g++){var k=f;""!==d[g]&&(k+="="+encodeURIComponent(String(d[g])));a.push(k)}}return this.ja=a.join("&")};e.Oa=function(){this.ja=null};e.clone=function(){var a=new vc;a.ja=this.ja;this.m&&(a.m=this.m.clone(),a.j=this.j);return a};e.Ma=function(a){a=String(a);this.O&&(a=a.toLowerCase());return a};
e.dd=function(a){a&&!this.O&&(this.$(),this.Oa(),this.m.forEach(function(a,c){var b=c.toLowerCase();c!=b&&(this.remove(c),this.Me(b,a))},this));this.O=a};e.extend=function(a){for(var b=0;b<arguments.length;b++)sc(arguments[b],function(a,b){this.add(b,a)},this)};function Hc(){this.Ae=w()}var Ic=null;Hc.prototype.set=function(a){this.Ae=a};Hc.prototype.reset=function(){this.set(w())};Hc.prototype.get=function(){return this.Ae};function Jc(){Ic||(Ic=new Hc)}function Kc(){Ic||(Ic=new Hc)}x(Kc,Jc);function Lc(a,b){this.b=a;this.a=b;this.f=this.A=null;this.cc=!1;this.K=null;this.w=-1;this.Dd=this.na=null}e=Lc.prototype;e.g=null;e.ga=function(a){this.A=a};e.connect=function(a){this.K=a;a=this.b.Vd(this.K);P(3);var b=this.b.Jb.ce;null!=b?(this.na=this.b.ab(b[0]),this.g=1,this.Ad()):(a.fc("MODE","init"),!this.b.ta&&this.b.aa&&a.fc("X-HTTP-Session-Id",this.b.aa),this.f=new R(this,this.a,void 0,void 0,void 0),this.f.ga(this.A),this.f.md(a,!1,null),this.g=0)};
e.Ad=function(){this.a.debug("TestConnection: starting stage 2");var a=this.b.Jb.rd;if(null!=a)this.a.debug(function(){return"TestConnection: skipping stage 2, precomputed result is "+a?"Buffered":"Unbuffered"}),P(4),a?(P(10),this.b.vb(this,!1)):(P(11),this.b.vb(this,!0));else{this.f=new R(this,this.a,void 0,void 0,void 0);this.f.ga(this.A);var b=this.b.Sd(this.na,this.K);P(4);b.fc("TYPE","xmlhttp");var c=this.b.aa,d=this.b.Mc;c&&d&&b.l(c,d);this.f.md(b,!1,this.na)}};e.Kb=function(a){return this.b.Kb(a)};
e.abort=function(){this.f&&(this.f.cancel(),this.f=null);this.w=-1};e.ge=function(){return!1};
e.xe=function(a,b){this.w=a.w;if(0==this.g)if(this.a.debug("TestConnection: Got data for stage 1"),this.rc(a),b){try{var c=this.b.mc.Bc(b)}catch(d){this.a.eb(d);this.b.gd(this);return}this.na=this.b.ab(c[0])}else this.a.debug("TestConnection: Null responseText"),this.b.gd(this);else 1==this.g&&(this.cc?P(6):"11111"==b?(P(5),this.cc=!0,this.cf()&&(this.w=200,this.f.cancel(),this.a.debug("Test connection succeeded; using streaming connection"),P(11),this.b.vb(this,!0))):(P(7),this.cc=!1))};
e.Vc=function(){this.w=this.f.w;this.f.S?0==this.g?(this.g=1,this.a.debug("TestConnection: request complete for initial check"),this.Ad()):1==this.g&&(this.a.debug("TestConnection: request complete for stage 2"),this.cc?(this.a.debug("Test connection succeeded; using streaming connection"),P(11),this.b.vb(this,!0)):(this.a.debug("Test connection failed; not using streaming"),P(10),this.b.vb(this,!1))):(this.a.debug("TestConnection: request failed, in state "+this.g),0==this.g?P(8):1==this.g&&P(9),
this.b.gd(this))};e.rc=function(a){if(!this.b.ta&&(a=a.h)){var b=a.lb("X-Client-Wire-Protocol");this.Dd=b?b:null;this.b.aa&&((a=a.lb("X-HTTP-Session-Id"))?this.b.Je(a):this.a.T("Missing X_HTTP_SESSION_ID in the handshake response"))}};e.hc=function(){return this.b.hc()};e.Ba=function(){return this.b.Ba()};e.cf=function(){return!C||10<=Number(Ua)};function Mc(){this.rd=this.ce=null};function Nc(a){this.D=new S;a&&this.addAll(a)}function Oc(a){var b=typeof a;return"object"==b&&a||"function"==b?"o"+(a[t]||(a[t]=++da)):b.substr(0,1)+a}e=Nc.prototype;e.C=function(){return this.D.C()};e.add=function(a){this.D.set(Oc(a),a)};e.addAll=function(a){a=rc(a);for(var b=a.length,c=0;c<b;c++)this.add(a[c])};e.qb=function(a){a=rc(a);for(var b=a.length,c=0;c<b;c++)this.remove(a[c])};e.remove=function(a){return this.D.remove(Oc(a))};e.clear=function(){this.D.clear()};e.X=function(){return this.D.X()};
e.contains=function(a){return this.D.va(Oc(a))};e.H=function(){return this.D.H()};e.clone=function(){return new Nc(this)};function Pc(a,b){this.Rc=a;this.map=b;this.context=null};function Qc(a){this.pe=a||Rc;h.PerformanceNavigationTiming?(a=h.performance.getEntriesByType("navigation"),a=0<a.length&&("hq"==a[0].nextHopProtocol||"h2"==a[0].nextHopProtocol)):a=!!(h.xc&&h.xc.ne&&h.xc.ne()&&h.xc.ne().th);this.Yb=a?this.pe:1;this.v=null;1<this.Yb&&(this.v=new Nc);this.f=null;this.ba=[]}var Rc=10;e=Qc.prototype;e.od=function(a){this.v||-1==a.indexOf("spdy")&&-1==a.indexOf("quic")&&-1==a.indexOf("h2")||(this.Yb=this.pe,this.v=new Nc,this.f&&(this.qc(this.f),this.f=null))};
e.he=function(){return this.f?!0:this.v?this.v.C()>=this.Yb:!1};e.Bf=function(){return this.f?1:this.v?this.v.C():0};e.Lc=function(a){return this.f?this.f==a:this.v?this.v.contains(a):!1};e.qc=function(a){this.v?this.v.add(a):this.f=a};e.Ce=function(a){this.f&&this.f==a?this.f=null:this.v&&this.v.contains(a)&&this.v.remove(a)};e.cancel=function(){this.ba=this.la();this.f?(this.f.cancel(),this.f=null):this.v&&!this.v.X()&&(na(this.v.H(),function(a){a.cancel()}),this.v.clear())};
e.la=function(){if(null!=this.f)return this.ba.concat(this.f.la());if(null!=this.v&&!this.v.X()){var a=this.ba;na(this.v.H(),function(b){a=a.concat(b.la())});return a}return ua(this.ba)};e.Ve=function(a){this.ba=this.ba.concat(a)};e.df=function(){this.ba.length=0};function Sc(){this.Ag=this.Ta=void 0}Sc.prototype.stringify=function(a){return h.JSON.stringify(a,this.Ta)};Sc.prototype.parse=function(a){return h.JSON.parse(a,this.Ag)};function Tc(){this.ng=new Sc}Tc.prototype.mf=function(a,b,c){var d=c||"";try{sc(a,function(a,c){var f=a;r(a)&&(f=(new I).ec(a));b.push(d+c+"="+encodeURIComponent(f))})}catch(f){throw b.push(d+"type="+encodeURIComponent("_badmap")),f;}};
Tc.prototype.nf=function(a,b,c){for(var d=-1;;){var f=["count="+b];-1==d?0<b?(d=a[0].Rc,f.push("ofs="+d)):d=0:f.push("ofs="+d);for(var g=!0,k=0;k<b;k++){var p=a[k].Rc,Vb=a[k].map;p-=d;if(0>p)d=Math.max(0,a[k].Rc-100),g=!1;else try{this.mf(Vb,f,"req"+p+"_")}catch(pd){c&&c(Vb)}}if(g)return f.join("&")}};Tc.prototype.Bc=function(a){return this.ng.parse(a)};function Uc(a,b){var c=new $b;c.debug("TestLoadImage: loading "+a);var d=new Image;d.onload=v(Vc,c,d,"TestLoadImage: loaded",!0,b);d.onerror=v(Vc,c,d,"TestLoadImage: error",!1,b);d.onabort=v(Vc,c,d,"TestLoadImage: abort",!1,b);d.ontimeout=v(Vc,c,d,"TestLoadImage: timeout",!1,b);h.setTimeout(function(){if(d.ontimeout)d.ontimeout()},1E4);d.src=a}function Vc(a,b,c,d,f){try{a.debug(c),b.onload=null,b.onerror=null,b.onabort=null,b.ontimeout=null,f(d)}catch(g){a.eb(g)}};function V(a){H.call(this);this.headers=new S;this.Ya=a||null;this.ha=!1;this.oc=this.c=null;this.je=this.Ub="";this.Pa=0;this.I="";this.Aa=this.Nc=this.Rb=this.Gc=!1;this.wb=0;this.jc=null;this.De=Wc;this.lc=this.pg=this.Bb=!1}x(V,H);var Wc="";V.prototype.s=Yb("goog.net.XhrIo");var Xc=/^https?$/i,Yc=["POST","PUT"];e=V.prototype;e.Ne=function(a){this.Bb=a};
e.send=function(a,b,c,d){if(this.c)throw Error("[goog.net.XhrIo] Object is active with another request="+this.Ub+"; newUri="+a);b=b?b.toUpperCase():"GET";this.Ub=a;this.I="";this.Pa=0;this.je=b;this.Gc=!1;this.ha=!0;this.c=this.hf();this.oc=this.Ya?this.Ya.Yd():lc.Yd();this.c.onreadystatechange=u(this.we,this);this.pg&&"onprogress"in this.c&&(this.c.onprogress=u(function(a){this.ue(a,!0)},this),this.c.upload&&(this.c.upload.onprogress=u(this.ue,this)));try{M(this.s,this.da("Opening Xhr")),this.Nc=
!0,this.c.open(b,String(a),!0),this.Nc=!1}catch(g){M(this.s,this.da("Error opening Xhr: "+g.message));this.Od(g);return}a=c||"";var f=this.headers.clone();d&&sc(d,function(a,b){f.set(b,a)});d=pa(f.W());c=h.FormData&&a instanceof h.FormData;!(0<=la(Yc,b))||d||c||f.set("Content-Type","application/x-www-form-urlencoded;charset=utf-8");f.forEach(function(a,b){this.c.setRequestHeader(b,a)},this);this.De&&(this.c.responseType=this.De);"withCredentials"in this.c&&this.c.withCredentials!==this.Bb&&(this.c.withCredentials=
this.Bb);try{this.Bd(),0<this.wb&&(this.lc=Zc(this.c),M(this.s,this.da("Will abort after "+this.wb+"ms if incomplete, xhr2 "+this.lc)),this.lc?(this.c.timeout=this.wb,this.c.ontimeout=u(this.Va,this)):this.jc=Kb(this.Va,this.wb,this)),M(this.s,this.da("Sending request")),this.Rb=!0,this.c.send(a),this.Rb=!1}catch(g){M(this.s,this.da("Send error: "+g.message)),this.Od(g)}};function Zc(a){return C&&Ta(9)&&"number"==typeof a.timeout&&void 0!==a.ontimeout}
function qa(a){return"content-type"==a.toLowerCase()}e.hf=function(){return this.Ya?this.Ya.Gd():lc.Gd()};e.Va=function(){"undefined"!=typeof goog&&this.c&&(this.I="Timed out after "+this.wb+"ms, aborting",this.Pa=8,M(this.s,this.da(this.I)),this.dispatchEvent("timeout"),this.abort(8))};e.Od=function(a){this.ha=!1;this.c&&(this.Aa=!0,this.c.abort(),this.Aa=!1);this.I=a;this.Pa=5;this.Md();this.Hb()};e.Md=function(){this.Gc||(this.Gc=!0,this.dispatchEvent("complete"),this.dispatchEvent("error"))};
e.abort=function(a){this.c&&this.ha&&(M(this.s,this.da("Aborting")),this.ha=!1,this.Aa=!0,this.c.abort(),this.Aa=!1,this.Pa=a||7,this.dispatchEvent("complete"),this.dispatchEvent("abort"),this.Hb())};e.F=function(){this.c&&(this.ha&&(this.ha=!1,this.Aa=!0,this.c.abort(),this.Aa=!1),this.Hb(!0));V.L.F.call(this)};e.we=function(){this.Ka||(this.Nc||this.Rb||this.Aa?this.ve():this.ig())};e.ig=function(){this.ve()};
e.ve=function(){if(this.ha&&"undefined"!=typeof goog)if(this.oc[1]&&4==this.ma()&&2==this.za())M(this.s,this.da("Local request error detected and ignored"));else if(this.Rb&&4==this.ma())Kb(this.we,0,this);else if(this.dispatchEvent("readystatechange"),this.Oc()){M(this.s,this.da("Request complete"));this.ha=!1;try{this.Vf()?(this.dispatchEvent("complete"),this.dispatchEvent("success")):(this.Pa=6,this.I=this.ae()+" ["+this.za()+"]",this.Md())}finally{this.Hb()}}};
e.ue=function(a,b){this.dispatchEvent($c(a,"progress"));this.dispatchEvent($c(a,b?"downloadprogress":"uploadprogress"))};function $c(a,b){return{type:b,lengthComputable:a.lengthComputable,loaded:a.loaded,total:a.total}}e.Hb=function(a){if(this.c){this.Bd();var b=this.c,c=this.oc[0]?aa:null;this.oc=this.c=null;a||this.dispatchEvent("ready");try{b.onreadystatechange=c}catch(d){(a=this.s)&&a.ca("Problem encountered resetting onreadystatechange: "+d.message,void 0)}}};
e.Bd=function(){this.c&&this.lc&&(this.c.ontimeout=null);this.jc&&(h.clearTimeout(this.jc),this.jc=null)};e.Ba=function(){return!!this.c};e.Oc=function(){return 4==this.ma()};e.Vf=function(){var a=this.za();a:switch(a){case 200:case 201:case 202:case 204:case 206:case 304:case 1223:var b=!0;break a;default:b=!1}return b||0===a&&!this.Sf()};
e.Sf=function(){var a=String(this.Ub).match(tc)[1]||null;!a&&h.self&&h.self.location&&(a=h.self.location.protocol,a=a.substr(0,a.length-1));return Xc.test(a?a.toLowerCase():"")};e.ma=function(){return this.c?this.c.readyState:0};e.za=function(){try{return 2<this.ma()?this.c.status:-1}catch(a){return-1}};e.ae=function(){try{return 2<this.ma()?this.c.statusText:""}catch(a){return M(this.s,"Can not get status: "+a.message),""}};
e.ya=function(){try{return this.c?this.c.responseText:""}catch(a){return M(this.s,"Can not get responseText: "+a.message),""}};e.Cf=function(a){if(this.c){var b=this.c.responseText;a&&0==b.indexOf(a)&&(b=b.substring(a.length));a:{a=b;if(h.JSON)try{var c=h.JSON.parse(a);break a}catch(d){}c=vb(a)}return c}};e.getResponseHeader=function(a){if(this.c&&this.Oc())return a=this.c.getResponseHeader(a),null===a?void 0:a};
e.getAllResponseHeaders=function(){return this.c&&this.Oc()?this.c.getAllResponseHeaders()||"":""};e.lb=function(a){return this.c?this.c.getResponseHeader(a):null};e.Xd=function(){return this.Pa};e.Jc=function(){return l(this.I)?this.I:String(this.I)};e.da=function(a){return a+" ["+this.je+" "+this.Ub+" "+this.za()+"]"};function ad(a){var b="";Aa(a,function(a,d){b+=d;b+=":";b+=a;b+="\r\n"});return b}function bd(a,b,c){a:{for(d in c){var d=!1;break a}d=!0}if(d)return a;c=ad(c);if(l(a)){b=encodeURIComponent(String(b));c=null!=c?"="+encodeURIComponent(String(c)):"";if(b+=c){c=a.indexOf("#");0>c&&(c=a.length);d=a.indexOf("?");if(0>d||d>c){d=c;var f=""}else f=a.substring(d+1,c);a=[a.substr(0,d),f,a.substr(c)];c=a[1];a[1]=b?c?c+"&"+b:b:c;a=a[0]+(a[1]?"?"+a[1]:"")+a[2]}return a}a.l(b,c);return a};function cd(a){this.Ed=22;this.He=0;this.M=[];this.a=new $b;this.Jb=new Mc;this.na=this.pd=this.ib=this.K=this.u=this.Mc=this.aa=this.hb=this.N=this.Sb=this.A=null;this.Xe=!0;this.eg=this.Zb=0;this.pf=!!m("internalChannelParams.failFast",a);this.jd=this.Ja=this.wa=this.ia=this.ea=this.i=null;this.We=!0;this.w=this.ke=this.Tb=-1;this.tc=this.Ha=this.La=0;this.Ze=m("internalChannelParams.baseRetryDelayMs",a)||5E3;this.yg=m("internalChannelParams.retryDelaySeedMs",a)||1E4;this.sf=m("internalChannelParams.forwardChannelMaxRetries",
a)||2;this.Rd=m("internalChannelParams.forwardChannelRequestTimeoutMs",a)||2E4;this.Ya=a&&a.uh||void 0;this.Eb=void 0;this.Ra=0;this.ic=a&&a.supportsCrossDomainXhr||!1;this.ra="";this.G=new Qc(a&&a.concurrentRequestLimit);this.mc=new Tc;this.ta=a&&void 0!==a.backgroundChannelTest?a.backgroundChannelTest:!0;(this.Qd=a&&a.fastHandshake||!1)&&!this.ta&&(this.a.T("Force backgroundChannelTest when fastHandshake is enabled."),this.ta=!0);a&&a.Ld&&this.a.Ld()}e=cd.prototype;e.vc=8;e.g=1;
e.connect=function(a,b,c,d,f){this.a.debug("connect()");P(0);this.K=b;this.hb=c||{};d&&void 0!==f&&(this.hb.OSID=d,this.hb.OAID=f);this.ta&&(this.a.debug("connect() bypassed channel-test."),this.Jb.ce=[],this.Jb.rd=!1);this.ff(a)};e.disconnect=function(){this.a.debug("disconnect()");this.td();if(3==this.g){var a=this.Zb++,b=this.ib.clone();b.l("SID",this.ra);b.l("RID",a);b.l("TYPE","terminate");this.Za(b);(new R(this,this.a,this.ra,a,void 0)).Bg(b)}this.te()};
e.ff=function(a){this.a.debug("connectTest_()");this.Ja=new Lc(this,this.a);null===this.N&&this.Ja.ga(this.A);var b=a;this.N&&this.A&&(b=bd(a,this.N,this.A));this.Ja.connect(b)};e.ef=function(){this.a.debug("connectChannel_()");this.ib=this.Vd(this.K);this.Fc()};e.td=function(){this.Ja&&(this.Ja.abort(),this.Ja=null);this.u&&(this.u.cancel(),this.u=null);this.ia&&(h.clearTimeout(this.ia),this.ia=null);this.Ib();this.G.cancel();this.ea&&(h.clearTimeout(this.ea),this.ea=null)};
e.ga=function(a){this.A=a};e.Kg=function(a){this.Sb=a};e.Ig=function(a){this.N=a};e.Jg=function(a){this.aa=a};e.Je=function(a){this.Mc=a};e.Le=function(a){this.Ra=a};e.Ng=function(){this.ic=!0};e.Ie=function(a){this.i=a};e.Rf=function(){return!this.jd};e.ad=function(a){1E3==this.M.length&&this.a.ca(function(){return"Already have 1000 queued maps upon queueing "+(new I).ec(a)});this.M.push(new Pc(this.eg++,a));3==this.g&&this.Fc()};e.uf=function(){return this.pf?0:this.sf};
e.ge=function(){return 0==this.g};e.getState=function(){return this.g};e.Fc=function(){this.G.he()||this.ea||(this.ea=Q(u(this.ze,this),0),this.La=0)};
e.bg=function(a){if(this.G.Bf()>=this.G.Yb-(this.ea?1:0))return this.a.ca("Unexpected retry request is scheduled."),!1;if(this.ea)return this.a.debug("Use the retry request that is already scheduled."),this.M=a.la().concat(this.M),!0;if(1==this.g||2==this.g||this.La>=this.uf())return!1;this.a.debug("Going to retry POST");this.ea=Q(u(this.ze,this,a),this.$d(this.La));this.La++;return!0};e.ze=function(a){this.ea=null;this.Sg(a)};
e.Sg=function(a){this.a.debug("startForwardChannel_");1==this.g?a?this.a.ca("Not supposed to retry the open"):(this.mg(),this.g=2):3==this.g&&(a?this.oe(a):0==this.M.length?this.a.debug("startForwardChannel_ returned: nothing to send"):this.G.he()?this.a.ca("startForwardChannel_ returned: connection already in progress"):(this.oe(),this.a.debug("startForwardChannel_ finished, sent request")))};
e.mg=function(){this.a.debug("open_()");this.Zb=Math.floor(1E5*Math.random());var a=this.Zb++,b=new R(this,this.a,"",a,void 0),c=this.A;this.Sb&&(c?(c=Da(c),Fa(c,this.Sb)):c=this.Sb);null===this.N&&b.ga(c);var d=this.Kd(b),f=this.ib.clone();f.l("RID",a);0<this.Ed&&f.l("CVER",this.Ed);this.ta&&this.aa&&f.l("X-HTTP-Session-Id",this.aa);this.Za(f);this.N&&c&&bd(f,this.N,c);this.G.qc(b);this.Qd?(f.l("$req",d),f.l("SID","null"),b.Gg(),b.nd(f,null)):b.nd(f,d)};
e.oe=function(a){var b=a?a.R:this.Zb++;var c=this.ib.clone();c.l("SID",this.ra);c.l("RID",b);c.l("AID",this.Tb);this.Za(c);this.N&&this.A&&bd(c,this.N,this.A);b=new R(this,this.a,this.ra,b,this.La+1);null===this.N&&b.ga(this.A);a&&this.vg(a);a=this.Kd(b);b.setTimeout(Math.round(.5*this.Rd)+Math.round(.5*this.Rd*Math.random()));this.G.qc(b);b.nd(c,a)};e.Za=function(a){this.i&&sc({},function(b,c){a.l(c,b)})};
e.Kd=function(a){var b=Math.min(this.M.length,1E3),c=this.i?u(this.i.Ye,this.i,this):null;c=this.mc.nf(this.M,b,c);a.Mg(this.M.splice(0,b));return c};e.vg=function(a){this.M=a.la().concat(this.M)};e.Nd=function(){if(!this.u&&!this.ia){this.tc=1;var a=this.ye;Eb||Fb();Hb||(Eb(),Hb=!0);Ib.add(a,this);this.Ha=0}};
e.Sc=function(){if(this.u||this.ia)return this.a.ca("Request already in progress"),!1;if(3<=this.Ha)return!1;this.a.debug("Going to retry GET");this.tc++;this.ia=Q(u(this.ye,this),this.$d(this.Ha));this.Ha++;return!0};e.ye=function(){this.ia=null;this.Qg()};
e.Qg=function(){this.a.debug("Creating new HttpRequest");this.u=new R(this,this.a,this.ra,"rpc",this.tc);null===this.N&&this.u.ga(this.A);this.u.Le(this.Ra);var a=this.pd.clone();a.l("RID","rpc");a.l("SID",this.ra);a.l("CI",this.jd?"0":"1");a.l("AID",this.Tb);this.Za(a);a.l("TYPE","xmlhttp");this.N&&this.A&&bd(a,this.N,this.A);this.Eb&&this.u.setTimeout(this.Eb);this.u.md(a,!0,this.na);this.a.debug("New Request created")};
e.vb=function(a,b){this.a.debug("Test Connection Finished");var c=a.Dd;c&&this.G.od(c);this.jd=this.We&&b;this.w=a.w;this.ef()};e.gd=function(a){this.a.debug("Test Connection Failed");this.w=a.w;this.sa(2)};
e.xe=function(a,b){if(0!=this.g&&(this.u==a||this.G.Lc(a)))if(this.w=a.w,!a.mb&&this.G.Lc(a)&&3==this.g){try{var c=this.mc.Bc(b)}catch(d){c=null}n(c)&&3==c.length?this.Gf(c,a):(this.a.debug("Bad POST response data returned"),this.sa(11))}else(a.mb||this.u==a)&&this.Ib(),va(b)||(c=this.mc.Bc(b),this.hg(c,a))};
e.Gf=function(a,b){0==a[0]?this.Ff(b):(this.ke=a[1],b=this.ke-this.Tb,0<b&&(a=a[2],this.a.debug(a+" bytes (in "+b+" arrays) are outstanding on the BackChannel"),this.Pg(a)&&!this.wa&&(this.wa=Q(u(this.fg,this),6E3))))};
e.Ff=function(a){this.a.debug("Server claims our backchannel is missing.");if(this.ia)this.a.debug("But we are currently starting the request.");else{if(this.u)if(this.u.rb+3E3<a.rb)this.Ib(),this.u.cancel(),this.u=null;else return;else this.a.T("We do not have a BackChannel established");this.Sc();P(18)}};e.Pg=function(a){return 37500>a&&!this.Rf()&&0==this.Ha};e.ab=function(a){return this.Xe?this.i?this.i.ab(a):a:null};
e.fg=function(){null!=this.wa&&(this.wa=null,this.u.cancel(),this.u=null,this.Sc(),P(19))};e.Ib=function(){null!=this.wa&&(h.clearTimeout(this.wa),this.wa=null)};
e.Vc=function(a){this.a.debug("Request complete");var b=null;if(this.u==a){this.Ib();this.u=null;var c=2}else if(this.G.Lc(a))b=a.la(),this.G.Ce(a),c=1;else return;this.w=a.w;if(0!=this.g)if(a.S)1==c?(dc(a.Da?a.Da.length:0,w()-a.rb,this.La),this.Fc()):this.Nd();else{var d=a.Jc();if(3==d||0==d&&0<this.w)this.a.debug("Not retrying due to error type");else{var f=this;this.a.debug(function(){return"Maybe retrying, last error: "+oc(d,f.w)});if(1==c&&this.bg(a)||2==c&&this.Sc())return;this.a.debug("Exceeded max number of retries")}b&&
0<b.length&&this.G.Ve(b);this.a.debug("Error: HTTP request failed");switch(d){case 1:this.sa(5);break;case 4:this.sa(10);break;case 3:this.sa(6);break;default:this.sa(2)}}};e.$d=function(a){var b=this.Ze+Math.floor(Math.random()*this.yg);this.Ba()||(this.a.debug("Inactive channel"),b*=2);return b*a};e.rc=function(a){if(this.ta&&(a=a.h)){var b=a.lb("X-Client-Wire-Protocol");b&&this.G.od(b);this.aa&&((a=a.lb("X-HTTP-Session-Id"))?(this.Je(a),this.ib.l(this.aa,a)):this.a.T("Missing X_HTTP_SESSION_ID in the handshake response"))}};
e.hg=function(a,b){for(var c=this.i&&this.i.uc?[]:null,d=0;d<a.length;d++){var f=a[d];this.Tb=f[0];f=f[1];if(2==this.g)if("c"==f[0]){this.ra=f[1];this.na=this.ab(f[2]);var g=f[3];null!=g&&(this.vc=g,this.a.info("VER="+this.vc));g=f[4];null!=g&&(this.He=g,this.a.info("SVER="+this.He));f=f[5];null!=f&&"number"==typeof f&&0<f&&(this.Eb=f*=1.5,this.a.info("backChannelRequestTimeoutMs_="+f));this.rc(b);this.g=3;this.i&&this.i.zd();this.Rg(b)}else"stop"!=f[0]&&"close"!=f[0]||this.sa(7);else 3==this.g&&
("stop"==f[0]||"close"==f[0]?(c&&0!=c.length&&(this.i.uc(this,c),c.length=0),"stop"==f[0]?this.sa(7):this.disconnect()):"noop"!=f[0]&&(c?c.push(f):this.i&&this.i.xd(f)),this.Ha=0)}c&&0!=c.length&&this.i.uc(this,c)};e.Rg=function(a){this.pd=this.Sd(this.na,this.K);a.mb?(this.a.debug("Upgrade the handshake request to a backchannel."),this.G.Ce(a),a.wg(this.Eb),this.u=a):this.Nd()};
e.sa=function(a){this.a.info("Error code "+a);if(2==a){var b=null;this.i&&(b=null);var c=u(this.Vg,this);b||(b=new U("//www.google.com/images/cleardot.gif"),h.location&&"http"==h.location.protocol||b.ub("https"),b.Vb());Uc(b.toString(),c)}else P(2);this.gg(a)};e.Vg=function(a){a?(this.a.info("Successfully pinged google.com"),P(2)):(this.a.info("Failed to ping google.com"),P(1))};e.gg=function(a){this.a.debug("HttpChannel: error - "+a);this.g=0;this.i&&this.i.wd(a);this.te();this.td()};
e.te=function(){this.g=0;this.w=-1;if(this.i){var a=this.G.la();if(0!=a.length||0!=this.M.length){var b=this;this.a.debug(function(){return"Number of undelivered maps, pending: "+a.length+", outgoing: "+b.M.length});this.G.df();ua(this.M);this.M.length=0}this.i.vd()}};e.Vd=function(a){a=this.Fd(null,a);this.a.debug("GetForwardChannelUri: "+a);return a};e.Sd=function(a,b){a=this.Fd(this.hc()?a:null,b);this.a.debug("GetBackChannelUri: "+a);return a};
e.Fd=function(a,b){var c=Ec(b);if(""!=c.xa)a&&c.sb(a+"."+c.xa),c.tb(c.Ca);else{var d=h.location;c=Fc(d.protocol,a?a+"."+d.hostname:d.hostname,+d.port,b)}this.hb&&Aa(this.hb,function(a,b){c.l(b,a)});a=this.aa;b=this.Mc;a&&b&&c.l(a,b);c.l("VER",this.vc);this.Za(c);return c};e.Kb=function(a){if(a&&!this.ic)throw Error("Can't create secondary domain capable XhrIo object.");a=new V(this.Ya);a.Ne(this.ic);return a};e.Ba=function(){return!!this.i&&this.i.Ba()};e.hc=function(){return this.ic};new Kc;
function dd(){}e=dd.prototype;e.uc=null;e.zd=function(){};e.xd=function(){};e.wd=function(){};e.vd=function(){};e.Ba=function(){return!0};e.Ye=function(){};e.ab=function(a){return a};function ed(a){for(var b=arguments[0],c=1;c<arguments.length;c++){var d=arguments[c];if(0==d.lastIndexOf("/",0))b=d;else{var f;(f=""==b)||(f=b.length-1,f=0<=f&&b.indexOf("/",f)==f);b=f?b+d:b+("/"+d)}}return b};function fd(){if(C&&!(10<=Number(Ua)))throw Error("Environmental error: no available transport.");}fd.prototype.gf=function(a,b){return new W(a,b)};
function W(a,b){H.call(this);this.b=new cd(b);this.zb=a;this.Wg=b&&b.testUrl?b.testUrl:ed(this.zb,"test");this.s=Yb("goog.labs.net.webChannel.WebChannelBaseTransport");this.Tc=b&&b.messageUrlParams||null;a=b&&b.messageHeaders||null;b&&b.clientProtocolHeaderRequired&&(a?a["X-Client-Protocol"]="webchannel":a={"X-Client-Protocol":"webchannel"});this.b.ga(a);a=b&&b.initMessageHeaders||null;b&&b.messageContentType&&(a?a["X-WebChannel-Content-Type"]=b.messageContentType:a={"X-WebChannel-Content-Type":b.messageContentType});
b&&b.Cd&&(a?a["X-WebChannel-Client-Profile"]=b.Cd:a={"X-WebChannel-Client-Profile":b.Cd});this.b.Kg(a);(a=b&&b.httpHeadersOverwriteParam)&&!va(a)&&this.b.Ig(a);this.Ug=b&&b.supportsCrossDomainXhr||!1;this.Cg=b&&b.sendRawJson||!1;(b=b&&b.httpSessionIdParam)&&!va(b)&&(this.b.Jg(b),a=this.Tc,null!==a&&b in a&&(a=this.Tc,b in a&&delete a[b],(a=this.s)&&a.T("Ignore httpSessionIdParam also specified with messageUrlParams: "+b,void 0)));this.yd=new X(this)}x(W,H);e=W.prototype;
e.addEventListener=function(a,b,c,d){W.L.addEventListener.call(this,a,b,c,d)};e.removeEventListener=function(a,b,c,d){W.L.removeEventListener.call(this,a,b,c,d)};e.open=function(){this.b.Ie(this.yd);this.Ug&&this.b.Ng();this.b.connect(this.Wg,this.zb,this.Tc||void 0)};e.close=function(){this.b.disconnect()};e.send=function(a){if(l(a)){var b={};b.__data__=a;this.b.ad(b)}else this.Cg?(b={},b.__data__=(new I).ec(a),this.b.ad(b)):this.b.ad(a)};
e.F=function(){this.b.Ie(null);delete this.yd;this.b.disconnect();delete this.b;W.L.F.call(this)};function gd(a){jc.call(this);var b=a.__sm__;if(b){a:{for(var c in b){a=c;break a}a=void 0}if(this.qe=a)a=this.qe,b=null!==b&&a in b?b[a]:void 0;this.data=b}else this.data=a}x(gd,jc);function hd(a){kc.call(this);this.status=1;this.errorCode=a}x(hd,kc);function X(a){this.b=a}x(X,dd);X.prototype.zd=function(){Zb(this.b.s,"WebChannel opened on "+this.b.zb);this.b.dispatchEvent("a")};X.prototype.xd=function(a){this.b.dispatchEvent(new gd(a))};
X.prototype.wd=function(a){Zb(this.b.s,"WebChannel aborted on "+this.b.zb+" due to channel error: "+a);this.b.dispatchEvent(new hd(a))};X.prototype.vd=function(){Zb(this.b.s,"WebChannel closed on "+this.b.zb);this.b.dispatchEvent("b")};var id=v(function(a,b){function c(){}c.prototype=a.prototype;var d=new c;a.apply(d,Array.prototype.slice.call(arguments,1));return d},fd);function jd(){this.V=[];this.Z=[]}e=jd.prototype;e.Zf=function(){0==this.V.length&&(this.V=this.Z,this.V.reverse(),this.Z=[])};e.enqueue=function(a){this.Z.push(a)};e.bb=function(){this.Zf();return this.V.pop()};e.C=function(){return this.V.length+this.Z.length};e.X=function(){return 0==this.V.length&&0==this.Z.length};e.clear=function(){this.V=[];this.Z=[]};e.contains=function(a){return 0<=la(this.V,a)||0<=la(this.Z,a)};
e.remove=function(a){var b=this.V;var c=ma(b,a);0<=c?(Array.prototype.splice.call(b,c,1),b=!0):b=!1;return b||sa(this.Z,a)};e.H=function(){for(var a=[],b=this.V.length-1;0<=b;--b)a.push(this.V[b]);var c=this.Z.length;for(b=0;b<c;++b)a.push(this.Z[b]);return a};function kd(a,b){z.call(this);this.re=a||0;this.Xb=b||10;if(this.re>this.Xb)throw Error(ld);this.fa=new jd;this.oa=new Nc;this.Cc=0;this.Pc=null;this.Db()}x(kd,z);var ld="[goog.structs.Pool] Min can not be greater than max";e=kd.prototype;e.Nb=function(){var a=w();if(!(null!=this.Pc&&a-this.Pc<this.Cc)){var b=this.ug();b&&(this.Pc=a,this.oa.add(b));return b}};e.sg=function(a){return this.oa.remove(a)?(this.pc(a),!0):!1};
e.ug=function(){for(var a;0<this.Wd()&&(a=this.fa.bb(),!this.Uc(a));)this.Db();!a&&this.C()<this.Xb&&(a=this.zc());return a};e.pc=function(a){this.oa.remove(a);this.Uc(a)&&this.C()<this.Xb?this.fa.enqueue(a):this.Dc(a)};e.Db=function(){for(var a=this.fa;this.C()<this.re;)a.enqueue(this.zc());for(;this.C()>this.Xb&&0<this.Wd();)this.Dc(a.bb())};e.zc=function(){return{}};e.Dc=function(a){if("function"==typeof a.cb)a.cb();else for(var b in a)a[b]=null};
e.Uc=function(a){return"function"==typeof a.bf?a.bf():!0};e.contains=function(a){return this.fa.contains(a)||this.oa.contains(a)};e.C=function(){return this.fa.C()+this.oa.C()};e.vf=function(){return this.oa.C()};e.Wd=function(){return this.fa.C()};e.X=function(){return this.fa.X()&&this.oa.X()};e.F=function(){kd.L.F.call(this);if(0<this.vf())throw Error("[goog.structs.Pool] Objects not released");delete this.oa;for(var a=this.fa;!a.X();)this.Dc(a.bb());delete this.fa};function md(a,b){this.ie=a;this.kd=b}md.prototype.getKey=function(){return this.ie};md.prototype.clone=function(){return new md(this.ie,this.kd)};function nd(a){this.Y=[];a&&this.Pf(a)}e=nd.prototype;e.fe=function(a,b){var c=this.Y;c.push(new md(a,b));this.dg(c.length-1)};e.Pf=function(a){if(a instanceof nd){var b=a.W();a=a.H();if(0>=this.C()){for(var c=this.Y,d=0;d<b.length;d++)c.push(new md(b[d],a[d]));return}}else b=Ca(a),a=Ba(a);for(d=0;d<b.length;d++)this.fe(b[d],a[d])};e.remove=function(){var a=this.Y,b=a.length,c=a[0];if(!(0>=b))return 1==b?ra(a):(a[0]=a.pop(),this.cg()),c.kd};
e.cg=function(){for(var a=0,b=this.Y,c=b.length,d=b[a];a<c>>1;){var f=this.xf(a),g=this.Df(a);f=g<c&&b[g].getKey()<b[f].getKey()?g:f;if(b[f].getKey()>d.getKey())break;b[a]=b[f];a=f}b[a]=d};e.dg=function(a){for(var b=this.Y,c=b[a];0<a;){var d=this.Af(a);if(b[d].getKey()>c.getKey())b[a]=b[d],a=d;else break}b[a]=c};e.xf=function(a){return 2*a+1};e.Df=function(a){return 2*a+2};e.Af=function(a){return a-1>>1};e.H=function(){for(var a=this.Y,b=[],c=a.length,d=0;d<c;d++)b.push(a[d].kd);return b};
e.W=function(){for(var a=this.Y,b=[],c=a.length,d=0;d<c;d++)b.push(a[d].getKey());return b};e.va=function(a){return oa(this.Y,function(b){return b.getKey()==a})};e.clone=function(){return new nd(this)};e.C=function(){return this.Y.length};e.X=function(){return 0==this.Y.length};e.clear=function(){ra(this.Y)};function od(){nd.call(this)}x(od,nd);od.prototype.enqueue=function(a,b){this.fe(a,b)};od.prototype.bb=function(){return this.remove()};function Y(a,b){this.Jd=void 0;this.dc=new od;kd.call(this,a,b)}x(Y,kd);e=Y.prototype;e.Nb=function(a,b){if(!a)return(a=Y.L.Nb.call(this))&&this.Cc&&(this.Jd=h.setTimeout(u(this.Ob,this),this.Cc)),a;this.dc.enqueue(void 0!==b?b:100,a);this.Ob()};e.Ob=function(){for(var a=this.dc;0<a.C();){var b=this.Nb();if(b)a.bb().apply(this,[b]);else break}};e.pc=function(a){Y.L.pc.call(this,a);this.Ob()};e.Db=function(){Y.L.Db.call(this);this.Ob()};
e.F=function(){Y.L.F.call(this);h.clearTimeout(this.Jd);this.dc.clear();this.dc=null};function Z(a,b,c,d){this.Nf=a;this.Bb=!!d;Y.call(this,b,c)}x(Z,Y);Z.prototype.zc=function(){var a=new V,b=this.Nf;b&&b.forEach(function(b,d){a.headers.set(d,b)});this.Bb&&a.Ne(!0);return a};Z.prototype.Uc=function(a){return!a.Ka&&!a.Ba()};fd.prototype.createWebChannel=fd.prototype.gf;W.prototype.send=W.prototype.send;W.prototype.open=W.prototype.open;W.prototype.close=W.prototype.close;ec.NO_ERROR=0;ec.TIMEOUT=8;ec.HTTP_ERROR=6;fc.COMPLETE="complete";hc.EventType=ic;ic.OPEN="a";ic.CLOSE="b";ic.ERROR="c";ic.MESSAGE="d";H.prototype.listen=H.prototype.ob;Z.prototype.getObject=Z.prototype.Nb;Z.prototype.releaseObject=Z.prototype.sg;V.prototype.listenOnce=V.prototype.Qc;V.prototype.getLastError=V.prototype.Jc;
V.prototype.getLastErrorCode=V.prototype.Xd;V.prototype.getStatus=V.prototype.za;V.prototype.getStatusText=V.prototype.ae;V.prototype.getResponseJson=V.prototype.Cf;V.prototype.getResponseText=V.prototype.ya;V.prototype.getResponseText=V.prototype.ya;V.prototype.send=V.prototype.send;module.exports={createWebChannelTransport:id,ErrorCode:ec,EventType:fc,WebChannel:hc,XhrIoPool:Z};}).call(typeof global !== 'undefined' ? global : typeof self !== 'undefined' ? self : typeof window !== 'undefined' ? window : {})
